// dependencies
import {lazy, Suspense} from 'react';

// hooks
import {useCustomMediaQuery} from 'hooks/useCustomMediaQuery';

// components
import {MainContent} from './MainContent';

// lazy load components
const Nav = lazy(() => import('./Nav'));

export const NavAndMain = () => {
    // media queries hook
    const {isDesktop} = useCustomMediaQuery();

    return (
        <div className="nav-and-main">
            {
                isDesktop &&
                <Suspense fallback={<div>loading...</div>}>
                    <Nav />
                </Suspense>
            }
            <MainContent />
        </div>
    );
};