// dependencies
import {Routes, Route} from "react-router-dom"
import {lazy, Suspense} from 'react';

// lazy load components
const Accueil = lazy(() => import('./Accueil'));
const LiaisonsIleDeBrehat = lazy(() => import('./LiaisonsIleDeBrehat'));
const TransportsMedicaux = lazy(() => import('./TransportsMedicaux'));
const TransportsDeGroupes = lazy(() => import('./TransportsDeGroupes'));
const TransportsVersGaresAeroportsEtPorts = lazy(() => import('./TransportsVersGaresAeroportsEtPorts'));
const Contact = lazy(() => import('./Contact'));

export const MainContent = () => {
    return (
        <div className="main-content">
            <Routes>
                <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><Accueil /></Suspense>} />
                <Route path="/liaisons-vers-ile-de-brehat" element={<Suspense fallback={<div>Loading...</div>}><LiaisonsIleDeBrehat /></Suspense>} />
                <Route path="/transports-medicaux" element={<Suspense fallback={<div>Loading...</div>}><TransportsMedicaux /></Suspense>} />
                <Route path="/transports-de-groupes" element={<Suspense fallback={<div>Loading...</div>}><TransportsDeGroupes /></Suspense>} />
                <Route path="/transports-vers-gares-aeroports-ports" element={<Suspense fallback={<div>Loading...</div>}><TransportsVersGaresAeroportsEtPorts /></Suspense>} />
                <Route path="/contact" element={<Suspense fallback={<div>Loading...</div>}><Contact /></Suspense>} />
            </Routes>
        </div>
    );
};