// dependencies
import {lazy, Suspense} from 'react';

// hooks
import {useCustomMediaQuery} from 'hooks/useCustomMediaQuery';

// components
import {LogoAndMainTitle} from './LogoAndMainTitle';
import {ClickToCall} from './ClickToCall';
// lazy load components
const Slogan = lazy(() => import ('./Slogan'));
const OpenMobileNav = lazy(() => import('./OpenMobileNav'));

export const Header = () => {
    // media queries hook
    const {isDesktop, isTablet, isMobile} = useCustomMediaQuery();

    return (
        <header>
            <LogoAndMainTitle />
            <ClickToCall />
            {
                isDesktop || isTablet ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Slogan />
                    </Suspense>
                ) : (
                    null
                )
            }
            {
                isTablet || isMobile ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        <OpenMobileNav />
                    </Suspense>
                ) : (
                    null
                )
            }
        </header>
    );
};