// dependencies
import {createContext, useState} from 'react';

const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
    const [isNavOpen, setNavOpen] = useState(false);

    const toggleNav = () => {
        setNavOpen((prevState) => !prevState);
    };

    return (
        <NavigationContext.Provider value={{ isNavOpen, toggleNav }}>
            {children}
        </NavigationContext.Provider>
    );
};

export {NavigationContext, NavigationProvider};
