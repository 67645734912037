// components
import {Logo} from './Logo';
import {MainTitle} from './MainTitle';

export const LogoAndMainTitle = () => {
    return (
        <div className="logo-and-main-title">
            <Logo />
            <MainTitle />
        </div>
    );
};