// dependencies
import {lazy, Suspense} from 'react';
import {BrowserRouter} from "react-router-dom";

// contexts
import {NavigationProvider} from 'contexts/NavigationContext';

// hooks
import {useCustomMediaQuery} from 'hooks/useCustomMediaQuery';

// components
import {Header} from 'components/Header';
import {NavAndMain} from 'components/NavAndMain';
import {Footer} from 'components/Footer';

// lazy load components
const MobileNav = lazy(() => import('components/MobileNav'));

export const App = () => {
    // contexts

    // media queries hook
    const {isTablet, isMobile} = useCustomMediaQuery();

    return (
        <BrowserRouter>
            <NavigationProvider>
                <Header />
                <NavAndMain />
                <Footer />
                {
                    isTablet || isMobile ? (
                        <Suspense fallback={<div>loading...</div>}>
                            <MobileNav />
                        </Suspense>
                    ) : (
                        null
                    )
                }
            </NavigationProvider>
        </BrowserRouter>
    );
};