export const Logo = () => (
    <div className="logo">
        <svg height="90" width="90" version="1.1" viewBox="0 0 42.333332 42.333333">
            <g transform="matrix(0.89514553,0,0,0.89514553,-30.351314,-96.179847)">
                <g transform="matrix(0.35277777,0,0,-0.35277777,37.598767,141.88292)">
                    <path className="logo-element" d="M 0,0 C 1.829,-0.017 3.357,-1.547 3.344,-3.348 3.33,-5.142 1.764,-6.675 -0.043,-6.664 -1.87,-6.652 -3.362,-5.14 -3.362,-3.302 -3.361,-1.481 -1.836,0.017 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,62.480818,110.51297)">
                    <path className="logo-element" d="m 0,0 c -0.023,-1.863 -1.5,-3.312 -3.357,-3.292 -1.841,0.02 -3.261,1.5 -3.247,3.387 0.013,1.867 1.476,3.308 3.344,3.294 C -1.421,3.375 0.023,1.874 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,78.819403,147.3054)">
                    <path className="logo-element" d="m 0,0 c 0.01,-1.897 -1.421,-3.354 -3.293,-3.353 -1.895,0.001 -3.309,1.432 -3.302,3.342 0.007,1.841 1.423,3.294 3.24,3.324 C -1.513,3.343 -0.01,1.86 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.863806,110.94456)">
                    <path className="logo-element" d="m 0,0 c 0.004,-1.921 -1.391,-3.326 -3.293,-3.317 -1.844,0.009 -3.254,1.411 -3.272,3.254 -0.018,1.867 1.443,3.338 3.304,3.325 C -1.42,3.25 -0.004,1.834 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,79.72075,144.8199)">
                    <path className="logo-element" d="M 0,0 C 0.019,-1.855 -1.449,-3.332 -3.309,-3.33 -5.13,-3.328 -6.558,-1.899 -6.574,-0.065 -6.59,1.813 -5.14,3.28 -3.276,3.27 -1.453,3.259 -0.019,1.827 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,39.29531,143.91609)">
                    <path className="logo-element" d="M 0,0 C 1.804,-0.005 3.287,-1.468 3.304,-3.261 3.322,-5.098 1.812,-6.611 -0.025,-6.596 -1.834,-6.582 -3.31,-5.124 -3.332,-3.332 -3.354,-1.504 -1.845,0.005 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,77.142156,149.31934)">
                    <path className="logo-element" d="m 0,0 c 0.003,-1.76 -1.383,-3.17 -3.127,-3.182 -1.807,-0.014 -3.216,1.387 -3.216,3.195 0.001,1.731 1.419,3.184 3.134,3.209 C -1.455,3.248 -0.003,1.791 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,37.79103,140.65914)">
                    <path className="logo-element" d="m 0,0 c -0.02,-1.729 -1.468,-3.174 -3.174,-3.169 -1.755,0.006 -3.216,1.5 -3.191,3.265 0.025,1.735 1.46,3.154 3.19,3.155 C -1.392,3.251 0.021,1.804 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,64.978943,110.91355)">
                    <path className="logo-element" d="M 0,0 C -0.003,-1.776 -1.369,-3.153 -3.136,-3.162 -4.951,-3.171 -6.339,-1.78 -6.333,0.041 -6.328,1.778 -4.914,3.213 -3.192,3.23 -1.422,3.247 0.003,1.805 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,41.525889,145.28017)">
                    <path className="logo-element" d="M 0,0 C 1.776,-0.006 3.16,-1.373 3.168,-3.129 3.176,-4.9 1.687,-6.405 -0.042,-6.372 -1.768,-6.339 -3.211,-4.891 -3.219,-3.184 -3.228,-1.382 -1.824,0.006 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,78.565932,143.36597)">
                    <path className="logo-element" d="M 0,0 C -1.772,0.011 -3.186,1.472 -3.146,3.252 -3.107,4.969 -1.655,6.396 0.047,6.391 1.793,6.385 3.269,4.891 3.246,3.153 3.223,1.368 1.803,-0.012 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,57.575513,112.21689)">
                    <path className="logo-element" d="m 0,0 c 0.009,-1.792 -1.402,-3.238 -3.164,-3.244 -1.73,-0.005 -3.232,1.504 -3.214,3.228 0.019,1.706 1.454,3.162 3.148,3.195 C -1.473,3.213 -0.009,1.772 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,54.78356,115.18223)">
                    <path className="logo-element" d="M 0,0 C -1.69,0.017 -3.09,1.45 -3.05,3.121 -3.01,4.809 -1.578,6.188 0.105,6.16 1.806,6.132 3.147,4.736 3.124,3.014 3.102,1.281 1.756,-0.018 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,73.992344,151.81647)">
                    <path className="logo-element" d="m 0,0 c -1.735,-0.015 -3.111,1.339 -3.11,3.06 10e-4,1.696 1.418,3.111 3.104,3.096 C 1.647,6.143 3.021,4.779 3.057,3.117 3.094,1.408 1.727,0.015 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,78.877646,139.87202)">
                    <path className="logo-element" d="m 0,0 c 0.016,-1.667 -1.413,-3.125 -3.068,-3.128 -1.66,-0.003 -3.11,1.432 -3.125,3.091 -0.014,1.676 1.401,3.101 3.088,3.11 C -1.4,3.081 -0.017,1.713 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,45.050386,146.8808)">
                    <path className="logo-element" d="m 0,0 c 0.005,-1.723 -1.351,-3.109 -3.053,-3.12 -1.699,-0.011 -3.079,1.363 -3.092,3.078 -0.013,1.7 1.364,3.111 3.052,3.128 C -1.406,3.103 -0.005,1.705 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,66.099365,113.04348)">
                    <path className="logo-element" d="M 0,0 C -1.756,0.019 -3.03,1.339 -3.008,3.116 -2.987,4.845 -1.613,6.17 0.12,6.131 1.783,6.093 3.11,4.719 3.101,3.043 3.093,1.308 1.741,-0.019 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,35.384486,138.17664)">
                    <path className="logo-element" d="M 0,0 C -0.014,1.738 1.337,3.118 3.054,3.119 4.757,3.12 6.136,1.729 6.133,0.015 6.13,-1.652 4.783,-3.023 3.12,-3.052 1.408,-3.083 0.014,-1.719 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,46.38445,147.68432)">
                    <path className="logo-element" d="m 0,0 c -1.646,-0.009 -3,1.328 -2.993,2.955 0.007,1.596 1.314,2.893 2.929,2.906 C 1.592,5.874 2.945,4.546 2.932,2.922 2.919,1.346 1.577,0.009 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,72.781893,151.60001)">
                    <path className="logo-element" d="M 0,0 C -0.013,-1.598 -1.324,-2.922 -2.906,-2.935 -4.544,-2.948 -5.854,-1.616 -5.84,0.05 -5.828,1.664 -4.533,2.986 -2.953,2.998 -1.349,3.01 0.013,1.627 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,67.966018,114.5061)">
                    <path className="logo-element" d="M 0,0 C -1.634,0.003 -2.896,1.257 -2.91,2.893 -2.925,4.559 -1.626,5.869 0.035,5.862 1.632,5.855 2.95,4.55 2.968,2.958 2.988,1.326 1.654,-0.003 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,35.810501,135.81056)">
                    <path className="logo-element" d="M 0,0 C -0.021,1.646 1.316,3.001 2.947,2.986 4.525,2.972 5.857,1.642 5.88,0.057 5.902,-1.541 4.518,-2.931 2.902,-2.931 1.316,-2.931 0.021,-1.623 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,54.840815,116.29754)">
                    <path className="logo-element" d="m 0,0 c 0.005,-1.669 -1.297,-2.971 -2.956,-2.956 -1.605,0.014 -2.877,1.306 -2.883,2.929 -0.006,1.69 1.257,2.942 2.953,2.927 C -1.229,2.885 -0.005,1.655 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,76.370419,138.9476)">
                    <path className="logo-element" d="M 0,0 C -1.6,0.009 -2.923,1.31 -2.945,2.898 -2.968,4.51 -1.597,5.874 0.037,5.866 1.63,5.857 2.954,4.545 2.973,2.955 2.993,1.318 1.658,-0.009 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,48.442697,146.6413)">
                    <path className="logo-element" d="M 0,0 C -1.552,0.009 -2.842,1.322 -2.823,2.876 -2.806,4.415 -1.449,5.73 0.078,5.69 1.574,5.65 2.868,4.333 2.87,2.849 2.871,1.309 1.544,-0.009 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,38.755983,133.66878)">
                    <path className="logo-element" d="m 0,0 c 0.016,-1.511 -1.229,-2.788 -2.763,-2.833 -1.562,-0.045 -2.899,1.228 -2.914,2.775 -0.014,1.566 1.255,2.871 2.809,2.886 C -1.306,2.844 -0.017,1.572 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,75.466497,136.62795)">
                    <path className="logo-element" d="m 0,0 c -0.004,-1.528 -1.25,-2.78 -2.784,-2.798 -1.592,-0.02 -2.874,1.219 -2.889,2.791 -0.015,1.554 1.279,2.86 2.833,2.859 C -1.268,2.851 0.004,1.574 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,54.633911,118.59437)">
                    <path className="logo-element" d="m 0,0 c 0.012,-1.599 -1.224,-2.859 -2.804,-2.858 -1.571,0 -2.828,1.274 -2.818,2.854 0.01,1.529 1.241,2.785 2.767,2.825 C -1.307,2.861 -0.012,1.581 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,70.359509,115.3425)">
                    <path className="logo-element" d="m 0,0 c -0.026,-1.577 -1.308,-2.81 -2.893,-2.783 -1.557,0.027 -2.85,1.367 -2.795,2.897 0.054,1.504 1.348,2.749 2.859,2.75 C -1.273,2.865 0.026,1.55 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,70.432464,151.85472)">
                    <path className="logo-element" d="m 0,0 c 0.01,-1.615 -1.207,-2.866 -2.795,-2.869 -1.568,-0.004 -2.837,1.276 -2.828,2.851 0.009,1.54 1.232,2.785 2.764,2.814 C -1.268,2.826 -0.01,1.596 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,67.2696,152.55759)">
                    <path className="logo-element" d="m 0,0 c -1.496,-0.017 -2.729,1.2 -2.743,2.708 -0.014,1.496 1.222,2.74 2.714,2.73 C 1.422,5.429 2.638,4.237 2.679,2.782 2.72,1.302 1.483,0.017 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,72.321871,135.14035)">
                    <path className="logo-element" d="M 0,0 C 1.535,-0.014 2.708,-1.207 2.682,-2.729 2.657,-4.224 1.506,-5.374 0.022,-5.386 -1.494,-5.398 -2.727,-4.194 -2.727,-2.702 -2.727,-1.187 -1.515,0.013 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,39.061948,130.96382)">
                    <path className="logo-element" d="M 0,0 C 1.542,0.02 2.752,-1.14 2.771,-2.655 2.79,-4.151 1.563,-5.394 0.062,-5.399 -1.428,-5.405 -2.672,-4.16 -2.667,-2.668 -2.661,-1.206 -1.475,-0.019 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,49.997071,143.09789)">
                    <path className="logo-element" d="M 0,0 C 1.504,-0.016 2.71,-1.256 2.681,-2.754 2.653,-4.196 1.434,-5.377 -0.032,-5.381 -1.555,-5.386 -2.748,-4.194 -2.743,-2.673 -2.738,-1.155 -1.536,0.017 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,70.212366,116.40838)">
                    <path className="logo-element" d="M 0,0 C 1.525,0.012 2.745,-1.185 2.745,-2.693 2.744,-4.177 1.479,-5.435 0.003,-5.419 -1.451,-5.404 -2.65,-4.209 -2.684,-2.741 -2.72,-1.228 -1.529,-0.012 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,53.309478,120.74596)">
                    <path className="logo-element" d="M 0,0 C -0.019,1.521 1.178,2.758 2.672,2.76 4.193,2.761 5.379,1.57 5.379,0.038 5.379,-1.462 4.248,-2.622 2.76,-2.646 1.205,-2.671 0.019,-1.534 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,71.106446,136.32914)">
                    <path className="logo-element" d="m 0,0 c -0.009,-1.454 -1.159,-2.596 -2.595,-2.578 -1.414,0.018 -2.6,1.238 -2.566,2.639 0.033,1.363 1.224,2.537 2.577,2.542 C -1.158,2.608 0.009,1.432 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,51.748789,142.09463)">
                    <path className="logo-element" d="m 0,0 c 0.006,-1.415 -1.188,-2.599 -2.611,-2.588 -1.39,0.01 -2.539,1.144 -2.563,2.53 -0.025,1.449 1.108,2.616 2.552,2.629 C -1.184,2.583 -0.006,1.429 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,54.609605,122.42585)">
                    <path className="logo-element" d="M 0,0 C 0.024,1.409 1.253,2.58 2.664,2.537 4.056,2.495 5.174,1.339 5.168,-0.054 5.162,-1.482 3.971,-2.671 2.562,-2.656 1.164,-2.641 -0.024,-1.41 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,70.647941,118.55197)">
                    <path className="logo-element" d="M 0,0 C 1.467,-0.005 2.57,-1.134 2.548,-2.607 2.526,-4.059 1.364,-5.178 -0.085,-5.145 -1.47,-5.114 -2.594,-3.962 -2.599,-2.57 -2.605,-1.127 -1.46,0.005 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,64.333748,150.8814)">
                    <path className="logo-element" d="M 0,0 C 0.037,1.401 1.186,2.528 2.555,2.509 3.969,2.489 5.148,1.28 5.123,-0.124 5.098,-1.57 3.94,-2.678 2.481,-2.65 1.009,-2.622 -0.04,-1.502 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,40.696508,131.41368)">
                    <path className="logo-element" d="M 0,0 C -1.384,-0.022 -2.539,1.097 -2.579,2.498 -2.622,3.985 -1.55,5.133 -0.094,5.162 1.353,5.19 2.541,4.064 2.567,2.641 2.592,1.229 1.419,0.023 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,69.251858,137.1751)">
                    <path className="logo-element" d="M 0,0 C 0.017,-1.352 -1.097,-2.486 -2.45,-2.496 -3.836,-2.505 -4.91,-1.444 -4.925,-0.05 -4.94,1.339 -3.878,2.426 -2.502,2.43 -1.125,2.435 -0.017,1.359 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,50.144779,140.05511)">
                    <path className="logo-element" d="M 0,0 C 0.038,1.331 1.13,2.405 2.436,2.396 3.825,2.387 4.943,1.212 4.896,-0.188 4.851,-1.541 3.69,-2.626 2.343,-2.574 0.988,-2.522 -0.04,-1.392 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,71.453791,121.49191)">
                    <path className="logo-element" d="m 0,0 c -0.005,-1.396 -1.061,-2.445 -2.465,-2.449 -1.361,-0.004 -2.468,1.107 -2.462,2.471 0.006,1.356 1.123,2.46 2.484,2.454 C -1.062,2.471 0.005,1.389 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.04305,123.59351)">
                    <path className="logo-element" d="m 0,0 c 0.007,-1.363 -1.098,-2.49 -2.448,-2.498 -1.325,-0.007 -2.476,1.143 -2.481,2.48 -0.005,1.356 1.103,2.468 2.464,2.471 C -1.076,2.455 -0.007,1.393 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,42.473838,128.66438)">
                    <path className="logo-element" d="M 0,0 C 1.396,0.002 2.463,-1.056 2.463,-2.443 2.464,-3.808 1.362,-4.925 0.011,-4.929 -1.342,-4.933 -2.436,-3.832 -2.447,-2.455 -2.457,-1.063 -1.4,-0.002 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,43.522082,128.95605)">
                    <path className="logo-element" d="M 0,0 C 0.019,1.326 1.071,2.357 2.371,2.323 3.662,2.289 4.682,1.207 4.655,-0.098 4.629,-1.33 3.56,-2.377 2.323,-2.38 1.022,-2.383 -0.018,-1.317 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,67.794074,138.52522)">
                    <path className="logo-element" d="m 0,0 c -0.014,-1.293 -1.105,-2.358 -2.381,-2.323 -1.285,0.035 -2.266,1.033 -2.274,2.314 -0.009,1.304 1.047,2.393 2.315,2.388 C -1.07,2.374 0.013,1.272 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,49.72709,138.20433)">
                    <path className="logo-element" d="M 0,0 C -0.004,1.277 1.086,2.379 2.352,2.38 3.6,2.381 4.698,1.244 4.674,-0.025 4.651,-1.288 3.629,-2.316 2.375,-2.338 1.096,-2.36 0.004,-1.285 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,61.26257,148.52566)">
                    <path className="logo-element" d="M 0,0 C -0.012,1.323 1.015,2.378 2.309,2.372 3.56,2.366 4.605,1.345 4.635,0.1 4.666,-1.211 3.654,-2.286 2.359,-2.317 1.05,-2.349 0.012,-1.33 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,62.626903,149.82)">
                    <path className="logo-element" d="M 0,0 C -0.013,1.428 1.096,2.567 2.492,2.56 3.842,2.554 4.971,1.452 5.003,0.108 5.037,-1.307 3.944,-2.468 2.546,-2.501 1.133,-2.535 0.013,-1.436 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,70.961419,123.37782)">
                    <path className="logo-element" d="m 0,0 c 0.015,-1.326 -0.998,-2.345 -2.327,-2.341 -1.276,0.003 -2.298,0.986 -2.343,2.253 -0.046,1.279 1.011,2.377 2.303,2.394 C -1.033,2.323 -0.015,1.332 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.023137,124.96684)">
                    <path className="logo-element" d="m 0,0 c -1.328,0.016 -2.298,1.034 -2.272,2.385 0.024,1.281 1.022,2.259 2.306,2.259 1.323,0 2.354,-1.032 2.335,-2.339 C 2.35,0.976 1.33,-0.016 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,50.263347,136.68065)">
                    <path className="logo-element" d="m 0,0 c -0.022,-1.216 -1.059,-2.2 -2.27,-2.155 -1.153,0.043 -2.157,1.071 -2.153,2.205 0.004,1.19 1.058,2.225 2.249,2.209 C -0.959,2.243 0.022,1.223 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,46.167986,129.48522)">
                    <path className="logo-element" d="M 0,0 C -1.162,0.013 -2.154,1.011 -2.162,2.178 -2.171,3.392 -1.166,4.409 0.048,4.415 1.24,4.421 2.268,3.381 2.258,2.179 2.248,0.967 1.232,-0.014 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,66.175883,140.98151)">
                    <path className="logo-element" d="M 0,0 C -1.204,-0.006 -2.191,0.937 -2.215,2.119 -2.24,3.367 -1.273,4.374 -0.038,4.387 1.185,4.4 2.216,3.371 2.195,2.159 2.176,0.983 1.182,0.006 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,60.934557,147.82017)">
                    <path className="logo-element" d="m 0,0 c -1.233,0 -2.223,1.002 -2.204,2.232 0.019,1.187 0.983,2.131 2.19,2.144 C 1.216,4.389 2.22,3.375 2.194,2.146 2.168,0.945 1.202,0 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,70.212401,125.09359)">
                    <path className="logo-element" d="m 0,0 c 0.023,-1.26 -0.922,-2.223 -2.184,-2.224 -1.217,-10e-4 -2.149,0.913 -2.171,2.132 -0.023,1.258 0.928,2.239 2.172,2.239 C -0.993,2.148 -0.022,1.193 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,60.900373,124.76413)">
                    <path className="logo-element" d="m 0,0 c -1.267,0.003 -2.189,0.951 -2.17,2.233 0.018,1.224 0.944,2.14 2.169,2.145 C 1.236,4.384 2.201,3.39 2.176,2.135 2.153,0.912 1.22,-0.003 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,68.553816,127.34961)">
                    <path className="logo-element" d="M 0,0 C -1.117,-0.018 -2.083,0.881 -2.125,1.981 -2.168,3.1 -1.181,4.105 -0.037,4.105 1.091,4.106 2.006,3.198 2.016,2.068 2.026,0.936 1.131,0.018 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,62.492671,123.95137)">
                    <path className="logo-element" d="M 0,0 C -1.126,0.004 -2.056,0.92 -2.061,2.03 -2.066,3.151 -1.147,4.098 -0.04,4.116 1.126,4.134 2.08,3.184 2.063,2.021 2.046,0.894 1.129,-0.004 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,48.023738,134.93874)">
                    <path className="logo-element" d="M 0,0 C 1.122,0.013 2.036,-0.883 2.061,-2.021 2.086,-3.14 1.191,-4.079 0.068,-4.11 -1.1,-4.143 -2.035,-3.221 -2.032,-2.041 -2.029,-0.91 -1.136,-0.013 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,66.04158,142.69742)">
                    <path className="logo-element" d="M 0,0 C -1.12,-0.001 -2.068,0.915 -2.086,2.015 -2.104,3.169 -1.137,4.128 0.02,4.104 1.157,4.08 2.036,3.177 2.028,2.04 2.02,0.895 1.131,0.001 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,60.076461,146.22047)">
                    <path className="logo-element" d="M 0,0 C -1.171,-0.015 -2.032,0.818 -2.051,1.985 -2.069,3.129 -1.207,4.057 -0.088,4.097 1.042,4.138 2.04,3.167 2.044,2.024 2.047,0.897 1.157,0.015 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,48.680328,128.73229)">
                    <path className="logo-element" d="m 0,0 c 0.001,-1.111 -0.914,-2.039 -2.036,-2.065 -1.148,-0.027 -2.102,0.94 -2.078,2.105 0.024,1.149 0.912,2.007 2.069,1.997 C -0.914,2.027 -0.001,1.118 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.483582,144.61476)">
                    <path className="logo-element" d="M 0,0 C -1.102,0.008 -1.959,0.889 -1.932,1.986 -1.906,3.038 -1.048,3.867 0.016,3.867 1.107,3.866 1.972,2.971 1.943,1.873 1.914,0.818 1.058,-0.007 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,66.491548,144.26915)">
                    <path className="logo-element" d="m 0,0 c -1.097,-0.019 -1.973,0.856 -1.964,1.963 0.008,1.051 0.866,1.924 1.9,1.935 C 1.01,3.909 1.929,2.997 1.917,1.931 1.906,0.892 1.038,0.018 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,68.136657,127.92478)">
                    <path className="logo-element" d="m 0,0 c -0.002,-1.104 -0.787,-1.911 -1.87,-1.921 -1.096,-0.01 -1.994,0.876 -1.984,1.957 0.01,1.03 0.892,1.922 1.919,1.94 C -0.845,1.994 0.002,1.13 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,62.98843,122.04651)">
                    <path className="logo-element" d="M 0,0 C -0.006,1.105 0.875,1.989 1.955,1.964 2.996,1.94 3.859,1.071 3.869,0.035 3.879,-1.041 2.976,-1.934 1.894,-1.919 0.831,-1.904 0.005,-1.067 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,46.419657,135.93389)">
                    <path className="logo-element" d="M 0,0 C -1.042,-0.007 -1.925,0.858 -1.949,1.91 -1.974,2.979 -1.153,3.841 -0.08,3.872 1.04,3.905 1.93,3.046 1.919,1.942 1.91,0.895 1.033,0.007 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,50.289135,129.02636)">
                    <path className="logo-element" d="M 0,0 C -0.015,-1.031 -0.906,-1.926 -1.921,-1.929 -2.98,-1.933 -3.909,-0.995 -3.892,0.06 -3.875,1.132 -2.943,2.008 -1.86,1.971 -0.816,1.935 0.015,1.054 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,51.121515,128.81364)">
                    <path className="logo-element" d="m 0,0 c 1.024,-0.001 1.837,-0.822 1.834,-1.85 -0.003,-1.04 -0.802,-1.823 -1.855,-1.818 -1.058,0.005 -1.83,0.781 -1.831,1.839 0,1.025 0.815,1.83 1.852,1.829"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,65.009211,120.62905)">
                    <path className="logo-element" d="m 0,0 c -0.025,-1.01 -0.897,-1.842 -1.903,-1.818 -0.992,0.023 -1.85,0.918 -1.826,1.904 0.024,0.996 0.903,1.826 1.914,1.808 C -0.802,1.876 0.025,1.012 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,66.328106,129.64923)">
                    <path className="logo-element" d="M 0,0 C -1.041,-0.009 -1.859,0.788 -1.867,1.82 -1.874,2.827 -1.035,3.677 -0.03,3.682 0.993,3.687 1.823,2.871 1.834,1.851 1.845,0.823 1.039,0.009 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,44.849196,134.71677)">
                    <path className="logo-element" d="M 0,0 C 1.036,-0.02 1.85,-0.854 1.821,-1.867 1.793,-2.871 0.929,-3.697 -0.075,-3.678 -1.089,-3.659 -1.918,-2.8 -1.894,-1.792 -1.871,-0.761 -1.045,0.02 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.112848,143.04063)">
                    <path className="logo-element" d="M 0,0 C -1.056,0.01 -1.852,0.803 -1.841,1.834 -1.831,2.855 -1.002,3.657 0.034,3.65 1.073,3.643 1.882,2.829 1.874,1.798 1.866,0.765 1.058,-0.01 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,67.37254,145.53781)">
                    <path className="logo-element" d="m 0,0 c -1.049,0.003 -1.835,0.788 -1.836,1.832 0,1.045 0.784,1.834 1.827,1.837 C 1.022,3.672 1.83,2.87 1.833,1.84 1.836,0.808 1.028,-0.003 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,65.728172,129.88844)">
                    <path className="logo-element" d="m 0,0 c 0.005,-0.971 -0.82,-1.779 -1.798,-1.762 -0.934,0.016 -1.712,0.803 -1.717,1.738 -0.006,0.984 0.796,1.77 1.79,1.753 C -0.783,1.714 -0.005,0.933 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.952229,141.52602)">
                    <path className="logo-element" d="M 0,0 C -0.946,0.016 -1.713,0.8 -1.717,1.758 -1.721,2.775 -0.928,3.547 0.084,3.511 1.028,3.477 1.8,2.658 1.77,1.722 1.741,0.787 0.916,-0.015 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,69.180173,145.79138)">
                    <path className="logo-element" d="m 0,0 c -0.016,-0.951 -0.805,-1.736 -1.743,-1.734 -0.976,0.002 -1.787,0.857 -1.743,1.835 0.044,0.948 0.854,1.706 1.795,1.678 C -0.755,1.752 0.016,0.94 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,43.463521,135.32725)">
                    <path className="logo-element" d="M 0,0 C 0.935,-0.001 1.705,-0.789 1.71,-1.75 1.715,-2.757 1.018,-3.487 0.032,-3.507 -0.981,-3.527 -1.756,-2.751 -1.736,-1.737 -1.718,-0.785 -0.933,0.001 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,64.516698,118.50381)">
                    <path className="logo-element" d="M 0,0 C 0.901,0.019 1.75,-0.793 1.792,-1.714 1.837,-2.681 1.112,-3.463 0.133,-3.506 -0.896,-3.552 -1.685,-2.81 -1.692,-1.792 -1.698,-0.85 -0.913,-0.019 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,52.488493,130.67606)">
                    <path className="logo-element" d="M 0,0 C -0.98,0.003 -1.734,0.743 -1.739,1.707 -1.744,2.664 -0.984,3.425 -0.014,3.436 0.994,3.448 1.806,2.65 1.785,1.67 1.764,0.724 0.987,-0.003 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,64.749179,117.77533)">
                    <path className="logo-element" d="m 0,0 c 0.002,-0.902 -0.692,-1.619 -1.565,-1.615 -0.868,0.003 -1.608,0.738 -1.611,1.601 C -3.18,0.85 -2.443,1.593 -1.58,1.594 -0.679,1.595 -0.002,0.913 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,63.905193,131.13311)">
                    <path className="logo-element" d="m 0,0 c -0.904,-0.007 -1.604,0.672 -1.617,1.57 -0.013,0.876 0.7,1.617 1.57,1.631 C 0.801,3.214 1.572,2.467 1.585,1.618 1.598,0.745 0.875,0.007 0,0"/>
                </g>
                <g itransform="matrix(0.35277777,0,0,-0.35277777,54.251183,130.77677)">
                    <path className="logo-element" d="m 0,0 c 0.005,-0.887 -0.698,-1.592 -1.591,-1.594 -0.892,-0.003 -1.606,0.706 -1.6,1.587 0.007,0.869 0.75,1.632 1.593,1.633 C -0.755,1.627 -0.005,0.864 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,42.445404,136.34311)">
                    <path className="logo-element" d="M 0,0 C 0.834,0.024 1.605,-0.734 1.621,-1.593 1.637,-2.492 0.96,-3.189 0.058,-3.203 -0.834,-3.217 -1.562,-2.526 -1.576,-1.653 -1.59,-0.807 -0.843,-0.024 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.542249,139.52178)">
                    <path className="logo-element" d="m 0,0 c -0.018,-0.869 -0.757,-1.59 -1.625,-1.584 -0.88,0.006 -1.584,0.729 -1.575,1.616 0.009,0.876 0.739,1.604 1.609,1.603 C -0.76,1.634 0.017,0.836 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,69.896348,145.60607)">
                    <path className="logo-element" d="M 0,0 C 0.873,0.019 1.613,-0.693 1.629,-1.565 1.645,-2.427 0.93,-3.178 0.062,-3.211 c -0.865,-0.032 -1.585,0.679 -1.6,1.58 C -1.553,-0.712 -0.9,-0.019 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.631291,138.2321)">
                    <path className="logo-element" d="m 0,0 c -0.013,-0.812 -0.622,-1.43 -1.41,-1.431 -0.805,-0.002 -1.502,0.706 -1.474,1.494 0.027,0.745 0.712,1.433 1.441,1.448 C -0.641,1.528 0.014,0.843 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,63.996386,116.72683)">
                    <path className="logo-element" d="m 0,0 c -0.066,-0.802 -0.828,-1.439 -1.614,-1.348 -0.79,0.091 -1.332,0.745 -1.288,1.551 0.048,0.854 0.697,1.4 1.581,1.329 C -0.567,1.471 0.061,0.743 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,42.388924,138.07151)">
                    <path className="logo-element" d="m 0,0 c 0.022,-0.769 -0.601,-1.445 -1.366,-1.484 -0.833,-0.042 -1.5,0.577 -1.521,1.412 C -2.908,0.778 -2.29,1.41 -1.44,1.409 -0.66,1.408 -0.022,0.783 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,54.724963,132.08427)">
                    <path className="logo-element" d="M 0,0 C -0.806,0.012 -1.444,0.707 -1.415,1.542 -1.387,2.359 -0.784,2.929 0.038,2.914 0.835,2.901 1.473,2.279 1.485,1.504 1.496,0.727 0.767,-0.011 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,71.724724,146.09622)">
                    <path className="logo-element" d="m 0,0 c -0.01,-0.767 -0.662,-1.426 -1.427,-1.443 -0.826,-0.018 -1.488,0.646 -1.475,1.478 0.013,0.801 0.624,1.429 1.409,1.446 C -0.697,1.499 0.011,0.797 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,63.212267,131.08877)">
                    <path className="logo-element" d="m 0,0 c -0.014,-0.838 -0.68,-1.467 -1.518,-1.431 -0.799,0.033 -1.378,0.654 -1.37,1.469 0.008,0.833 0.682,1.476 1.507,1.437 C -0.602,1.439 0.013,0.783 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,72.813432,145.65803)">
                    <path className="logo-element" d="m 0,0 c -0.015,-0.754 -0.618,-1.334 -1.375,-1.322 -0.755,0.012 -1.339,0.62 -1.319,1.374 0.018,0.733 0.653,1.349 1.376,1.337 C -0.59,1.376 0.015,0.739 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,56.090107,132.38078)">
                    <path className="logo-element" d="m 0,0 c 0.014,-0.765 -0.554,-1.367 -1.304,-1.381 -0.753,-0.015 -1.341,0.565 -1.353,1.333 -0.013,0.765 0.558,1.371 1.303,1.385 C -0.613,1.35 -0.014,0.759 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.877671,137.02006)">
                    <path className="logo-element" d="m 0,0 c 0.021,-0.739 -0.58,-1.386 -1.311,-1.411 -0.735,-0.025 -1.374,0.577 -1.396,1.317 -0.022,0.732 0.578,1.382 1.305,1.414 C -0.688,1.351 -0.02,0.722 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,41.207119,139.29629)">
                    <path className="logo-element" d="M 0,0 C -0.015,0.731 0.596,1.361 1.336,1.377 2.097,1.394 2.695,0.826 2.718,0.062 2.741,-0.67 2.13,-1.31 1.397,-1.323 0.661,-1.337 0.015,-0.724 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,61.532869,131.91568)">
                    <path className="logo-element" d="m 0,0 c -0.759,0.007 -1.355,0.619 -1.34,1.376 0.016,0.763 0.628,1.349 1.4,1.301 C 0.867,2.627 1.304,2.172 1.332,1.365 1.359,0.585 0.766,-0.007 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,62.99993,115.9249)">
                    <path className="logo-element" d="m 0,0 c 0.005,-0.764 -0.592,-1.38 -1.341,-1.382 -0.739,-0.002 -1.325,0.621 -1.314,1.396 0.011,0.748 0.547,1.304 1.28,1.326 C -0.621,1.363 -0.005,0.764 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.338309,135.92408)">
                    <path className="logo-element" d="M 0,0 C 0.064,0.624 0.457,1.035 1.179,1.071 1.867,1.106 2.412,0.531 2.376,-0.168 2.339,-0.907 1.891,-1.289 1.199,-1.355 0.552,-1.416 -0.016,-0.786 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,41.864238,139.97051)">
                    <path className="logo-element" d="M 0,0 C 0.663,-0.02 1.227,-0.632 1.15,-1.304 1.069,-2.015 0.63,-2.452 -0.081,-2.415 -0.77,-2.378 -1.213,-1.939 -1.251,-1.22 -1.286,-0.572 -0.651,0.019 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,56.31906,132.80091)">
                    <path className="logo-element" d="M 0,0 C 0.772,0.016 1.365,-0.564 1.329,-1.236 1.29,-1.959 0.876,-2.372 0.16,-2.418 -0.531,-2.462 -1.06,-1.902 -1.046,-1.187 -1.03,-0.44 -0.583,-0.099 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,61.901486,115.5287)">
                    <path className="logo-element" d="m 0,0 c -0.019,-0.675 -0.42,-1.09 -1.124,-1.172 -0.659,-0.076 -1.257,0.518 -1.253,1.202 0.004,0.742 0.439,1.142 1.125,1.236 C -0.614,1.354 -0.011,0.735 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,60.857581,131.71855)">
                    <path className="logo-element" d="M 0,0 C -0.061,-0.6 -0.452,-1.014 -1.17,-1.058 -1.857,-1.1 -2.393,-0.532 -2.368,0.183 -2.341,0.92 -1.903,1.316 -1.207,1.375 -0.56,1.43 0.014,0.793 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,73.643835,144.97992)">
                    <path className="logo-element" d="M 0,0 C -0.068,-0.638 -0.439,-1.075 -1.154,-1.135 -1.803,-1.19 -2.404,-0.578 -2.402,0.079 -2.4,0.731 -1.788,1.317 -1.14,1.288 -0.517,1.259 0.006,0.699 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.008426,131.89864)">
                    <path className="logo-element" d="M 0,0 C 0.102,0.998 0.455,1.408 1.147,1.397 1.83,1.386 2.242,0.973 2.238,0.302 2.234,-0.373 1.776,-0.855 1.134,-0.75 0.681,-0.676 0.293,-0.206 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,60.542516,114.98605)">
                    <path className="logo-element" d="m 0,0 c 0.286,-0.423 0.742,-0.817 0.818,-1.275 0.11,-0.668 -0.4,-1.074 -1.072,-1.067 -0.672,0.006 -1.182,0.43 -1.07,1.084 0.077,0.456 0.561,0.843 0.864,1.261 C -0.307,0.002 -0.153,0.001 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,60.178837,135.41129)">
                    <path className="logo-element" d="m 0,0 c -0.749,0.04 -1.127,0.461 -1.123,1.122 0.004,0.674 0.463,1.19 1.108,1.047 C 0.392,2.079 0.893,1.544 0.99,1.126 1.139,0.477 0.658,0.064 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,57.092419,133.66112)">
                    <path className="logo-element" d="M 0,0 C 0.205,-0.279 0.697,-0.666 0.773,-1.121 0.882,-1.776 0.373,-2.216 -0.306,-2.219 -0.937,-2.222 -1.348,-1.854 -1.365,-1.19 -1.382,-0.504 -1.048,-0.088 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,73.42924,144.11206)">
                    <path className="logo-element" d="M 0,0 C 0.033,0.681 0.38,1.107 1.078,1.113 1.787,1.12 2.247,0.624 2.127,0.003 2.047,-0.409 1.529,-0.934 1.114,-1.038 0.451,-1.205 0.059,-0.703 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,42.687798,141.43938)">
                    <path className="logo-element" d="M 0,0 C -0.277,-0.2 -0.659,-0.677 -1.109,-0.754 -1.747,-0.862 -2.214,-0.372 -2.227,0.289 -2.24,0.948 -1.835,1.376 -1.145,1.387 -0.461,1.397 -0.082,1.021 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,43.337226,142.07099)">
                    <path className="logo-element" d="M 0,0 C -0.37,-0.265 -0.772,-0.772 -1.102,-0.729 -1.466,-0.681 -1.96,-0.162 -2.024,0.215 -2.068,0.475 -1.496,1.073 -1.166,1.1 -0.801,1.13 -0.397,0.679 -0.01,0.438 -0.007,0.292 -0.003,0.146 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,59.54038,116.13611)">
                    <path className="logo-element" d="M 0,0 C -0.446,0.623 -0.854,0.951 -0.911,1.331 -0.997,1.903 -0.575,2.42 0.011,2.281 0.388,2.192 0.845,1.681 0.913,1.296 0.965,1.005 0.459,0.615 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,57.393268,135.24647)">
                    <path className="logo-element" d="M 0,0 C -0.248,0.385 -0.711,0.788 -0.682,1.151 -0.656,1.483 -0.135,1.967 0.234,2.037 0.827,2.148 1.241,1.652 1.141,1.068 1.075,0.685 0.681,0.358 0.435,0.007 0.29,0.004 0.145,0.002 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,60.509601,134.58282)">
                    <path className="logo-element" d="M 0,0 C -0.221,0.389 -0.647,0.802 -0.604,1.159 -0.564,1.497 0.041,2.07 0.269,2.014 0.654,1.92 1.151,1.438 1.198,1.068 1.239,0.741 0.73,0.346 0.463,-0.02 0.309,-0.013 0.154,-0.007 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.409057,132.20408)">
                    <path className="logo-element" d="m 0,0 c -0.266,0.363 -0.778,0.758 -0.735,1.08 0.05,0.371 0.545,0.85 0.93,0.946 C 0.419,2.083 1.023,1.508 1.065,1.169 1.108,0.816 0.681,0.406 0.459,0.02 0.306,0.013 0.153,0.007 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,74.145803,142.81845)">
                    <path className="logo-element" d="m 0,0 c 0.241,-0.354 0.62,-0.683 0.691,-1.068 0.113,-0.612 -0.349,-1.058 -0.918,-0.953 -0.364,0.067 -0.882,0.559 -0.903,0.888 -0.023,0.365 0.441,0.761 0.693,1.143 C -0.292,0.006 -0.146,0.003 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,74.484999,142.35635)">
                    <path className="logo-element" d="m 0,0 c -0.511,-0.399 -0.855,-0.857 -1.095,-0.808 -0.324,0.066 -0.568,0.523 -0.846,0.812 0.27,0.259 0.524,0.697 0.816,0.724 C -0.842,0.755 -0.52,0.359 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.273802,135.62084)">
                    <path className="logo-element" d="m 0,0 c -0.56,-0.35 -0.903,-0.733 -1.172,-0.687 -0.297,0.051 -0.527,0.496 -0.786,0.769 0.281,0.257 0.546,0.689 0.848,0.717 C -0.84,0.825 -0.529,0.404 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.411597,116.07183)">
                    <path className="logo-element" d="M 0,0 C 0.516,0.39 0.836,0.817 1.112,0.79 1.405,0.762 1.85,0.316 1.868,0.029 1.884,-0.223 1.424,-0.69 1.134,-0.725 0.856,-0.758 0.526,-0.358 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,43.657266,142.0277)">
                    <path className="logo-element" d="M 0,0 C 0.376,-0.523 0.78,-0.84 0.76,-1.127 0.74,-1.417 0.302,-1.889 0.042,-1.893 -0.231,-1.897 -0.706,-1.459 -0.734,-1.178 -0.764,-0.885 -0.366,-0.549 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,57.646139,131.38352)">
                    <path className="logo-element" d="M 0,0 C 0.339,-0.545 0.722,-0.888 0.677,-1.16 0.628,-1.452 0.16,-1.894 -0.093,-1.874 -0.371,-1.851 -0.798,-1.388 -0.816,-1.095 -0.834,-0.818 -0.406,-0.513 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,61.074681,133.07868)">
                    <path className="logo-element" d="M 0,0 C 0.343,-0.547 0.724,-0.885 0.683,-1.162 0.64,-1.452 0.194,-1.684 -0.076,-1.941 -0.331,-1.665 -0.769,-1.401 -0.788,-1.109 -0.807,-0.828 -0.392,-0.519 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,61.528212,133.2175)">
                    <path className="logo-element" d="m 0,0 c -0.338,0.455 -0.683,0.715 -0.669,0.956 0.013,0.252 0.371,0.485 0.578,0.726 C 0.144,1.465 0.528,1.273 0.57,1.024 0.609,0.786 0.291,0.489 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.173824,136.64213)">
                    <path className="logo-element" d="M 0,0 C -0.305,0.446 -0.648,0.729 -0.619,0.966 -0.59,1.211 -0.195,1.588 0.034,1.583 0.265,1.579 0.651,1.189 0.669,0.947 0.686,0.706 0.329,0.438 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,44.404167,142.98923)">
                    <path className="logo-element" d="m 0,0 c -0.327,0.449 -0.678,0.721 -0.654,0.956 0.024,0.248 0.406,0.46 0.632,0.688 C 0.201,1.419 0.576,1.213 0.605,0.966 0.633,0.73 0.301,0.451 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,56.536759,131.71583)">
                    <path className="logo-element" d="M 0,0 C 0.429,0.346 0.684,0.709 0.915,0.694 1.155,0.679 1.542,0.305 1.551,0.076 1.56,-0.154 1.208,-0.55 0.965,-0.592 0.742,-0.631 0.453,-0.297 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.595041,116.6167)">
                    <path className="logo-element" d="m 0,0 c -0.462,-0.286 -0.754,-0.608 -0.973,-0.565 -0.248,0.049 -0.61,0.441 -0.593,0.658 0.019,0.237 0.404,0.6 0.65,0.618 C -0.69,0.727 -0.435,0.358 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,73.88196,141.27114)">
                    <path className="logo-element" d="m 0,0 c 0.336,-0.466 0.677,-0.732 0.663,-0.977 -0.015,-0.249 -0.379,-0.478 -0.59,-0.716 -0.226,0.222 -0.6,0.422 -0.636,0.672 C -0.599,-0.78 -0.287,-0.487 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.164123,117.16082)">
                    <path className="logo-element" d="m 0,0 c -0.391,-0.229 -0.617,-0.462 -0.837,-0.457 -0.164,0.004 -0.482,0.362 -0.447,0.479 0.065,0.222 0.321,0.515 0.521,0.534 C -0.575,0.574 -0.357,0.276 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,45.346825,142.70151)">
                    <path className="logo-element" d="m 0,0 c -0.401,-0.248 -0.648,-0.519 -0.832,-0.482 -0.203,0.041 -0.43,0.347 -0.483,0.575 -0.025,0.11 0.331,0.436 0.507,0.431 C -0.593,0.518 -0.386,0.266 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,61.9477,132.64628)">
                    <path className="logo-element" d="m 0,0 c -0.27,0.397 -0.543,0.622 -0.523,0.818 0.02,0.203 0.324,0.378 0.503,0.565 C 0.148,1.216 0.446,1.053 0.452,0.88 0.46,0.657 0.234,0.426 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,73.890427,141.02144)">
                    <path className="logo-element" d="m 0,0 c -0.412,-0.266 -0.625,-0.506 -0.838,-0.507 -0.16,0 -0.44,0.306 -0.445,0.483 -0.006,0.171 0.267,0.488 0.425,0.493 C -0.643,0.476 -0.421,0.246 0,0"/>
                </g>
                <g transform="matrix(0.35277777,0,0,-0.35277777,58.677838,136.93116)">
                    <path className="logo-element" d="m 0,0 c -0.372,-0.239 -0.61,-0.508 -0.78,-0.471 -0.193,0.043 -0.416,0.347 -0.448,0.564 -0.02,0.13 0.309,0.447 0.465,0.436 C -0.553,0.515 -0.359,0.266 0,0"/>
                </g>
            </g>
        </svg>
    </div>
);
